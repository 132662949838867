import asyncRoute from 'app/core/asyncRoute';

// Auth
export const SingIn = asyncRoute(() => import('auth/SignIn'));
export const SignUp = asyncRoute(() => import('auth/SignUp'));
export const Activation = asyncRoute(() => import('auth/Activation'));
export const Reset = asyncRoute(() => import('auth/Reset'));
export const Privacy = asyncRoute(() => import('auth/Privacy'));
export const EULA = asyncRoute(() => import('auth/EULA'));

// Home
export const Home = asyncRoute(() => import('home/Home'));
export const Dashboard = asyncRoute(() => import('dashboard/Dashboard'));

// Board
export const Board = asyncRoute(() => import('board/Board'));

// Mypage
export const MyPage = asyncRoute(() => import('mypage/MyPage'));

// saju
export const Saju = asyncRoute(() => import('saju/Saju'));
export const SajuResult = asyncRoute(() => import('saju/SajuResult'));
export const CustomerPage = asyncRoute(() => import('saju/CustomerPage'));

//layout
export const LayoutTest = asyncRoute(() => import('layout/LayoutTest'));
export const TestXD = asyncRoute(() => import('TestXD'));

export const LeftSidebar = asyncRoute(() => import('layout/LeftSidebar'));
export const RightSidebar = asyncRoute(() => import('layout/RightSidebar'));

export const RouterCard = asyncRoute(() => import('layout/RouterCard'));
export const NewsCard = asyncRoute(() => import('layout/NewsCard'));

export const ContentTabs = asyncRoute(() => import('layout/ContentTabs'));
export const ContentHorizeonTabs = asyncRoute(() => import('layout/ContentHorizeonTabs'));

// game
export const BlankGame = asyncRoute(() => import('game/BlankGameHome'));
