import * as ApiType from 'types/api.types';
import { getHandleActions } from 'app/core/connection';
import { Api } from 'app/core/Api';
import { U } from 'app/core/U';

export type SajuState = {
    result: any,
}

const initState:SajuState = {
    result: {},
}

export const SajuAction = {
    getSaju: async (person:any) => {
        let res:any;
        if (person.mj.trim().length === 0) {
            res = await Api.list('/custom/saju/', {
                year: person.year,
                month: person.month,
                day: person.day,
                hour: person.hour,
                minute: person.minute,
                solunar: person.solunar,
                gender: person.gender,
            })
        } else {
            res = await Api.list('/custom/saju/', {
                입력명조: person.mj,
                gender: person.gender,
            })
        }
       
        return Promise.resolve({
            result: res
        })
    },
}

export default getHandleActions(SajuAction, initState)