import { getHandleActions } from 'app/core/connection';
import * as api from 'saju/menu/Menu.api';

export type MenuState = {
  leftOpen: boolean;
  rightOpen: boolean;
}

const initState:MenuState = {
  leftOpen: false,
  rightOpen: false
};

export const MenuAction = {
  openLeftMenu: (state:any) => {
    return Promise.resolve({
      ...state,
      leftOpen: true
    })
  },
  closeLeftMenu: (state:any) => {
    return Promise.resolve({
      ...state,
      leftOpen: false
    })
  },
  openRightMenu: (state:any) => {
    return Promise.resolve({
      ...state,
      rightOpen: true
    })
  },
  closeRightMenu: (state:any) => {
    return Promise.resolve({
      ...state,
      rightOpen: false
    })
  },
}

export default getHandleActions(MenuAction, initState)