import { combineReducers } from 'redux';
import { penderReducer } from 'redux-pender';

import menu, { MenuState } from 'saju/menu/Menu.action';
import auth, { AuthState } from 'auth/Auth.action';
import board, { BoardState } from 'board/Board.action';
import dashboard, { DashboardState } from 'dashboard/Dashboard.action';
import mypage, { MypageState } from 'mypage/Mypage.action';
import shared, { SharedState } from 'component/Shared.action';
import saju, { SajuState } from 'saju/Saju.action';
import person, { PersonState } from 'saju/person/Person.action';

export default combineReducers({
  menu,
  auth,
  board,
  dashboard,
  mypage,
  saju,
  shared,
  person,
  pender: penderReducer,
})

export type RootState = {
  menu: MenuState;
  auth: AuthState;
  board: BoardState;
  dashboard: DashboardState;
  mypage: MypageState;
  saju: SajuState;
  shared: SharedState;
  person: PersonState;
}
