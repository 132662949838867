import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "home/Header";
import { Footer } from "home/Footer";
import { connectWithoutDone, binding } from "./core/connection";
import { RootState } from "./Reducers";
import { Dispatch } from "redux";
import { AuthAction } from "auth/Auth.action";
import Alert from "component/Alert";
import Notification from "component/Notification";
import {
  Home,
  SingIn,
  SignUp,
  Activation,
  Reset,
  MyPage,
  Board,
  Dashboard,
  Saju,
  LayoutTest,
  TestXD,
  BlankGame,
  Privacy,
  EULA,
} from "app/Routes";
import { NaverAuthCallbackComponent } from "auth/SocialLogin";
import Fcm from "../component/Fcm";
import { I18next } from "component/I18next";

interface Props {
  AuthAct: typeof AuthAction;
  location: any;
}

class App extends Component<Props> {
  render() {
    return (
      <HelmetProvider>
        {/* <Helmet>
                    <title>React Router & SSR</title>
                </Helmet> */}
        {/* <I18next/>
                <Header /> */}
        {this.props.location.pathname.includes("/blank-game") ||
        this.props.location.pathname.includes("/privacy") ||
        this.props.location.pathname.includes("/EULA") ? (
          ""
        ) : (
          <>
            <I18next />
            <Header />
          </>
        )}
        <div
          className={
            this.props.location.pathname.includes("/blank-game") ||
            this.props.location.pathname.includes("/privacy") ||
            this.props.location.pathname.includes("/EULA")
              ? ""
              : "container"
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/signin" component={SingIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/activation" component={Activation} />
            <Route exact path="/naver" component={NaverAuthCallbackComponent} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/EULA" component={EULA} />

            <Route exact path="/layouttest" component={LayoutTest} />
            <Route exact path="/test" component={TestXD} />

            <Route path="/saju" component={Saju} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/reset" component={Reset} />
            <Route path="/mypage" component={MyPage} />
            <Route path="/board" component={Board} />
            <Route
              path="/firebase-cloud-messaging-push-scope"
              component={Home}
            />

            <Route
              path="/blank-game/:main-:sub/:ad/:profile"
              component={() => <BlankGame />}
            />
            <Route
              path="/blank-game/:main-:sub/:ad"
              component={() => <BlankGame />}
            />
            <Route
              path="/blank-game/:main-:sub"
              component={() => <BlankGame />}
            />
            <Route path="/blank-game" component={() => <BlankGame />} />

            <Route path="*" component={() => <Redirect to="/" />} />
          </Switch>
        </div>
        {this.props.location.pathname.includes("/blank-game") ||
        this.props.location.pathname.includes("/privacy") ||
        this.props.location.pathname.includes("/EULA") ? (
          ""
        ) : (
          <>
            <Footer />
            <Alert />
            <Fcm />
            <Notification />
          </>
        )}
        {/* <Footer />
                <Alert />
                <Fcm />
                <Notification /> */}
      </HelmetProvider>
    );
  }
}

export default connectWithoutDone(
  (state: RootState) => ({}),
  (dispatch: Dispatch) => ({
    AuthAct: binding(AuthAction, dispatch),
  }),
  App
);
