import React from 'react';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';
import { translation } from 'component/I18next';

export class Footer extends React.Component {

  t = translation('footer', [
    "EULA",
    "privacy"
  ])

  render() {
    return (
      <footer className="footer" style={{ position: "relative", bottom: 0 }}>
        <div className="container footer-bg">
          <div className="row align-">
            <div className="col flex-left">
              <span className="" >COPYRIGHT 2020. UGL Soft Co., Ltd. ALL RIGHTS RESERVED, Contact Us: developer@uglsoft.co.kr</span>
            </div>
            <div className="col mobile-bot">
              <ul className="list-inline pd-63">
                <li className="list-inline-item">
                  <a className="color-lightgray" href="#">{this.t.terms}</a>
                </li>
                <li className="list-inline-item color-lightgray m-0-30">
                  |
                </li>
                <li className="list-inline-item">
                  <a className="color-lightgray" href="/privacy" target='_blank'>{this.t.privacy}</a>
                  <a className="color-lightgray" href="/EULA" target='_blank'>{this.t.EULA}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}