import React from 'react';
import { connectWithoutDone, binding } from 'app/core/connection';
import { RootState } from 'app/Reducers';
import { Dispatch, bindActionCreators } from 'redux';
import { History } from 'history';
import { FaBars } from 'react-icons/fa';
import { Collapse, Button } from 'reactstrap';
import { translation } from 'component/I18next';
import { createStyles, Theme, makeStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { Drawer, Icon, Divider } from '@material-ui/core';

import { AuthState, AuthAction } from 'auth/Auth.action';
import { PersonState, PersonAction } from 'saju/person/Person.action';

const styles = (theme: Theme) =>
  createStyles({
    root: {
    }
  })

interface Props extends WithStyles<typeof styles> {
  auth: AuthState
  AuthAction: typeof AuthAction
  person: PersonState
  PersonAction: typeof PersonAction
  location: Location
  history: History
}

class Header extends React.Component<Props> {

  // headerList = ['guide', 'saju', 'economic_unse', 'couple_gunghap', 'statistics'];
  headerList = ['guide', 'saju'];
  t = translation('header', this.headerList);

  state = {
    collapse: false,
    useDrawer: false,
    openDrawer: false,

    todaySolar: '',
    todayLunar: '',
    갑자: ''
  }

  async componentDidMount() {
    this.showDate();
    this.resize();

    window.addEventListener('resize', this.resize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this))
  }

  showDate() {
    let solar: any = new Date();
    
    const year = solar.getFullYear();
    let month = solar.getMonth() + 1;
    const day = solar.getDate();

    solar = `${year}-${month}-${day}`;

    PersonAction.getDt(solar).then((res: any) => {
      solar = res.양력;
      const lunar = res.음력;
      
      let 갑자 = res.갑자.split(' ');
      갑자 = `${갑자[0]} ${갑자[1]} ${갑자[2]}`;

      this.setState({ todaySolar: solar, todayLunar: lunar, 갑자: 갑자 });
    })
  }

  resize() {
    const width = window.innerWidth;
    if (width <= 768) {
      this.setState({ useDrawer: true });
    } else {
      this.setState({ useDrawer: false });
    }
  }

  render() {
    const { auth, AuthAction } = this.props;
    const { useDrawer, openDrawer, todaySolar, todayLunar, 갑자 } = this.state;

    return (
      <nav className={this.props.location.pathname === '/' ?
        'navbar navbar-expand-lg navbar-dark' : "navbar navbar-expand-lg navbar-dark navbar-shrink"} id="mainNav">
        <div className="container header">

          {/* <a className="navbar-brand js-scroll-trigger" onClick={()=>this.props.history.push('/')}>Start Bootstrap</a>
        <Button className="navbar-toggler navbar-toggler-right" onClick={()=>this.setState({collapse:!this.state.collapse})}>
          Menu
          <FaBars />
        </Button> */}

          <div className="logo" onClick={() => this.props.history.push('/')}></div>

          <div className="topline">
            <div className="solunar">
              <i className="solunar-i" onClick={() => this.props.history.push('/')} />
              <span>오늘은 양력 {todaySolar} 음력 {todayLunar} {갑자}</span>
              <button className="solunar-b" onClick={() => { }}>양력음력변환기</button>
            </div>

            <div className='side-set'>
              <div className="login-set">
                {(auth.userProfile) ?
                  [<i key={0} className="user-i" onClick={() => this.props.history.push('/mypage')}></i>,
                  <span key={1} className="c-p" onClick={() => this.props.history.push('/mypage')}>{auth.userProfile.name}</span>,
                  <i key={2} className="logout-i" onClick={() => AuthAction.signOut()}></i>]
                  :
                  [<a key={0} className="" onClick={() => this.props.history.push('/signin')}>로그인</a>,
                  <span key={1} className="pipe">|</span>,
                  <a key={2} className="" onClick={() => this.props.history.push('/signup')}>회원가입</a>]
                }
              </div>
              <div className="instant-search">

                <input type="text" className="input-sm" placeholder="간편검색" />
                <i className="search" onClick={() => { }}></i>
                {useDrawer && <div className='btn-drawer' onClick={() => this.setState({ openDrawer: true })}>
                  <Icon fontSize='inherit'>reorder</Icon>
                </div>}
              </div>

            </div>
          </div>

          <div className='main-menu'>
            {!useDrawer && <>
              {this.headerList.map((h, index) => {
                return (
                  <div key={index}>
                    <a style={(index + 1) === this.headerList.length ? {} : { marginRight: '30px' }} onClick={() => this.props.history.push('/' + h)}>{this.t[h]}</a>
                  </div>
                )
              })}
            </>}
          </div>

          {/* <Collapse isOpen={this.state.collapse} className="header-menu" id="navbarResponsive" key="4">
          <ul className="navbar-nav text-uppercase ml-auto">
            {this.headerList.map((h, index) => {
              return <li className="nav-item" key={index}>
                <a className="nav-link js-scroll-trigger" onClick={()=>this.props.history.push('/'+ h)}>{this.t[h]}</a>
              </li>
            })}
          </ul>
        </Collapse> */}

          <Drawer anchor='left' open={openDrawer} onClose={() => this.setState({ openDrawer: false })}>
            <div className='drawer-menu'>
              <Divider variant='middle' className='divider' />
              {this.headerList.map((h, index) => {
                return (
                  <div key={index} style={(index + 1) === this.headerList.length ? {} : { marginBottom: '12px' }} >
                    <a onClick={() => this.props.history.push('/' + h)}>{this.t[h]}</a>
                  </div>
                )
              })}
              <Divider variant='middle' className='divider' />
            </div>
          </Drawer>

        </div>
      </nav>
    )
  }
}

export default connectWithoutDone(
  (state: RootState) => ({
    auth: state.auth,
    person: state.person
  }),
  (dispatch: Dispatch) => ({
    AuthAction: binding(AuthAction, dispatch),
    PersonAction: binding(PersonAction, dispatch)
  }),
  withStyles(styles)(Header)
)