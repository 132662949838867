import { Api } from 'app/core/Api';
import * as ApiType from 'types/api.types';
import { getHandleActions } from 'app/core/connection';
import * as CustomType from 'types/custom.types';
import { SajuState, SajuAction } from 'saju/Saju.action';

export type PersonState = {
    groupList: CustomType.person.GroupList
    personListAll: ApiType.PersonItem[]
    personListByGroup: CustomType.person.ItemList
}

const initState: PersonState = {
    groupList: {
        depth1: [],
        depth2: [],
        depth3: []
    },
    personListAll: [],
    personListByGroup: {
        group: -1,
        list: []
    },
}

export const PersonAction = {
    getDt: async (dt: string, solunar?: string) => {
        const res = await Api.list('/custom/dt/', {
            dt: dt,
            solunar: solunar ? solunar : '양력'
        });
        return res;
    },
    getAllPerson: async (profile: number) => {
        const res: any = await Api.list('/api-person/item/', {
            profile: profile,
        });
        res.map(async (obj: any) => {
            const otherBirthday: any = await PersonAction.getDt(obj.birthday.split('T')[0], obj.solunar);
            if (obj.solunar === '양력') {
                obj.otherBirthday = otherBirthday.음력;
            } else {
                obj.otherBirthday = otherBirthday.양력;
            }
        })
        return Promise.resolve({
            personListAll: res
        })
    },
    getPersonByGroup: async (group: any) => {
        let list: any = [];
        if (group.id) {
            list = await Api.list('/api-person/item/', {
                profile: group.profile,
                persongroup: group.id
            });
        } else {
            const res: any = await Api.list('/api-person/item/', {
                profile: group.profile,
            });
            res.map((item: any) => {
                if (!item.persongroup) list.push(item);
            });
        }

        await Promise.all(list.map(async (obj: any) => {
            const otherBirthday: any = await PersonAction.getDt(obj.birthday.split('T')[0], obj.solunar);
            if (obj.solunar === '양력') {
                obj.otherBirthday = otherBirthday.음력;
            } else {
                obj.otherBirthday = otherBirthday.양력;
            }
        }));

        const personList = { group: group.id, list: list };
        
        return Promise.resolve({
            personListByGroup: personList
        })
    },
    addPerson: async (person: any) => {
        const group = { id: person.persongroup, profile: person.profile };

        // find mj
        const birthday = person.birthday.split(' ')[0];
        const birthtime = person.birthday.split(' ')[1];
        const sajuPerson: any = {
            year: birthday.split('-')[0],
            month: birthday.split('-')[1],
            day: birthday.split('-')[2],
            hour: birthtime.split(':')[0],
            minute: birthtime.split(':')[1],
            solunar: person.solunar,
            gender: person.gender,
            mj: ''
        }
        const saju: any = await SajuAction.getSaju(sajuPerson);
        const sajupalja = saju.result.args.한자명조;

        return await Api.create('/api-person/item/', {
            name: person.name,
            birthday: person.birthday,
            relationType: 'notNull',
            solunar: person.solunar,
            gender: person.gender,
            sajupalja: sajupalja,
            content: person.content ? person.content : null,
            profile: person.profile,
            persongroup: person.persongroup === 0 ? null : person.persongroup
        }).then(() => PersonAction.getPersonByGroup(group))
    },
    updatePerson: async (person: any) => {
        const group = { id: person.persongroup, profile: person.profile };
        return await Api.patch('/api-person/item/', person.id, {
            name: person.name,
            birthday: person.birthday,
            solunar: person.solunar,
            gender: person.gender,
            sajupalja: person.sajupalja,
            persongroup: person.persongroup,
            content: person.content
        }).then(() => PersonAction.getPersonByGroup(group))
    },
    deletePerson: async (person: any) => {
        const group = { id: person.persongroup, profile: person.profile };
        return await Api.delete('/api-person/item/', person.id)
            .then(() => PersonAction.getPersonByGroup(group))
    },
    getGroupList: async (profile: number) => {
        const res = await Api.list('/api-person/group/', {
            profile: profile,
        });
        const list: any = res;

        const grpList: any = {
            depth1: [],
            depth2: [],
            depth3: []
        };

        // sort by depth
        list.map((item: any) => {
            if (item.pparent) grpList.depth3.push(item);
            if (item.parent && !item.pparent) grpList.depth2.push(item);
            if (!item.parent) grpList.depth1.push(item);
        })

        return Promise.resolve({
            groupList: grpList
        })
    },
    addGroup: async (group: any) => {
        return await Api.create('/api-person/group/', {
            name: group.name,
            profile: group.profile,
            parent: group.parent ? group.parent : null,
            pparent: group.pparent ? group.pparent : null,
        }).then(() => PersonAction.getGroupList(group.profile));
    },
    updateGroup: async (group: any) => {
        return await Api.patch('/api-person/group/', group.id, {
            name: group.name,
            parent: group.parent,
            pparent: group.pparent
        }).then(() => PersonAction.getGroupList(group.profile));
    },
    updateGroupParent: async (group: any) => {
        const child: any = await Api.list('/api-person/group/', {
            profile: group.pofile,
            parent: group.id
        });

        child.map((item: any) => {
            item.pparent = group.parent;
            PersonAction.updateGroup(item);
        });

        return await Api.patch('/api-person/group/', group.id, {
            parent: group.parent,
            pparent: group.pparent,
        }).then(() => PersonAction.getGroupList(group.profile));
    },
    deleteGroup: async (group: any) => {
        return await Api.delete('/api-person/group/', group.id)
            .then(() => PersonAction.getGroupList(group.profile));
    }
}

export default getHandleActions(PersonAction, initState)